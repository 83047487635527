/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Stack,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  HelpOutline as HelpIcon,
  Add as AddIcon,
  CheckCircle as ApproveIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import axios from 'axios';
import englishLabels from './labels/englishLabels'; // Import the labels

const Gallery = () => {
  const [selectedClass, setSelectedClass] = useState('ALL');
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [notes, setNotes] = useState('');
  const [taggedStudents, setTaggedStudents] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [approveMediaList, setApproveMediaList] = useState([]);
  const [sentMediaList, setSentMediaList] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);
  const [openMediaDetailsDialog, setOpenMediaDetailsDialog] = useState(false);
  const [selectedMediaDetails, setSelectedMediaDetails] = useState(null);

  useEffect(() => {
    fetchAllMedia();
    fetchApproveMedia();
    fetchSentMedia();
  }, []);

  const fetchAllMedia = async () => {
    try {
      const response = await axios.get('https://tinytots-backend.pixeligator.com/api/gallery');
      setMediaList(response.data);
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  };

  const fetchApproveMedia = async () => {
    try {
      const response = await axios.get('https://tinytots-backend.pixeligator.com/api/gallery/unapproved');
      setApproveMediaList(response.data);
    } catch (error) {
      console.error('Error fetching media to approve:', error);
    }
  };

  const fetchSentMedia = async () => {
    try {
      const response = await axios.get('https://tinytots-backend.pixeligator.com/api/gallery/sent-today');
      setSentMediaList(response.data);
    } catch (error) {
      console.error('Error fetching sent media:', error);
    }
  };

  const handleClassChange = (event, newValue) => {
    setSelectedClass(newValue);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('notes', notes);
    formData.append('studentIds', JSON.stringify(taggedStudents.map((student) => student.id)));

    try {
      await axios.post('https://tinytots-backend.pixeligator.com/api/gallery/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setOpenUploadDialog(false);
      setFile(null);
      setNotes('');
      setTaggedStudents([]);
      fetchAllMedia();
      fetchApproveMedia(); // Add this line
    } catch (error) {
      console.error('Error uploading media:', error);
    }
  };
  useEffect(() => {
    // Fetch all students when component is mounted
    fetchAllStudents();
  }, []);

  const fetchAllStudents = async () => {
    try {
      const response = await axios.get('https://tinytots-backend.pixeligator.com/api/students-get-all');
      setStudentOptions(response.data.students);
    } catch (error) {
      console.error('Error fetching all students:', error);
    }
  };

  const searchStudents = async (query) => {
    // Only search if query length is at least 2 characters
    if (query.length < 2) return;

    try {
      // Fetch all students from the new API
      const response = await axios.get('https://tinytots-backend.pixeligator.com/api/students-get-all');

      // Filter students based on the query entered by the user
      const filteredStudents = response.data.students.filter((student) =>
        `${student.first_name} ${student.last_name}`.toLowerCase().includes(query.toLowerCase())
      );

      // Update the student options state
      setStudentOptions(filteredStudents);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const renderTaggedStudents = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
      {taggedStudents.map((student) => (
        <Box
          key={student.id}
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'primary.main',
            color: 'white',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 16,
          }}
        >
          {(student.first_name?.[0] || '') + (student.last_name?.[0] || '')}
        </Box>
      ))}
    </Box>
  );

  const getImageUrl = (filePath) => {
    return `http://localhost:5000/${filePath}`;
  };

  const handleApprove = async (mediaId) => {
    try {
      await axios.put(`https://tinytots-backend.pixeligator.com/api/gallery/approve/${mediaId}`);
      await fetchApproveMedia();
      await fetchSentMedia(); // Fetch sent media after approving
    } catch (error) {
      console.error('Error approving media:', error);
    }
  };

  const handleDeleteClick = (media) => {
    setMediaToDelete(media);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!mediaToDelete) return;

    try {
      await axios.delete(`https://tinytots-backend.pixeligator.com/api/gallery/${mediaToDelete.id}`);
      fetchApproveMedia();
      fetchAllMedia();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  const handleApproveAll = async () => {
    try {
      await Promise.all(
        approveMediaList.map((media) =>
          axios.put(`https://tinytots-backend.pixeligator.com/api/gallery/approve/${media.id}`)
        )
      );
      await fetchApproveMedia();
      await fetchSentMedia();
    } catch (error) {
      console.error('Error approving all media:', error);
    }
  };

  const handlePreviewClick = (media) => {
    setSelectedMediaDetails(media);
    setOpenMediaDetailsDialog(true);
  };

  const handleApproveFromModal = async () => {
    if (selectedMediaDetails) {
      await handleApprove(selectedMediaDetails.id);
      setOpenMediaDetailsDialog(false);
    }
  };

  const renderApproveMediaTable = () => (
    <>
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Approve Media</Typography>
          <Button variant="contained" color="primary" onClick={handleApproveAll} startIcon={<ApproveIcon />}>
            Approve All
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Preview</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Tagged Students</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approveMediaList.map((media) => (
                <TableRow key={media.id}>
                  <TableCell>
                    <Box
                      sx={{
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePreviewClick(media)}
                    >
                      <img
                        src={getImageUrl(media.file_path)}
                        alt={media.notes}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>{media.notes}</TableCell>
                  <TableCell>
                    {media.tagged_students &&
                      media.tagged_students.split(',').map((student) => {
                        const [id, firstName, lastName] = student.split('|');
                        return (
                          <Box
                            key={id}
                            sx={{
                              width: 30,
                              height: 30,
                              borderRadius: '50%',
                              bgcolor: 'primary.main',
                              color: 'white',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: 1,
                              fontSize: 14,
                            }}
                          >
                            {(firstName?.[0] || '') + (lastName?.[0] || '')}
                          </Box>
                        );
                      })}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleApprove(media.id)} color="success">
                      <ApproveIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(media)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {approveMediaList.length === 0 && (
                <TableRow>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    No media to approve at this time.
                  </Typography>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );

  const renderMedia = (mediaData) => (
    <Grid container spacing={2}>
      {mediaData.map((media) => (
        <Grid item xs={12} sm={6} md={4} key={media.id}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <img
              src={getImageUrl(media.file_path)}
              alt={media.notes}
              style={{ width: '100%', height: 200, objectFit: 'cover' }}
            />
            <Typography variant="body2">{media.notes}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  const getFilteredData = (type) => {
    if (type === 'approveMedia') {
      return approveMediaList;
      // eslint-disable-next-line no-else-return
    } else if (type === 'sentMedia') {
      return sentMediaList;
    }
    return [];
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setOpenImageDialog(true);
  };

  const handleMediaClick = (media) => {
    setSelectedMediaDetails(media);
    setOpenMediaDetailsDialog(true);
  };

  const renderMediaSentToday = () => (
    <Grid container spacing={2}>
      {sentMediaList.map((media) => (
        <Grid item xs={6} sm={4} md={3} key={media.id}>
          <Paper
            elevation={3}
            sx={{
              p: 1,
              cursor: 'pointer',
              '&:hover': { boxShadow: 6 },
            }}
            onClick={() => handleMediaClick(media)}
          >
            <img
              src={getImageUrl(media.file_path)}
              alt={media.notes}
              style={{ width: '100%', height: 150, objectFit: 'cover' }}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {englishLabels.gallery.title} {/* Use the label */}
        </Typography>
        <Tooltip title={englishLabels.gallery.manageMedia}>
          <IconButton size="small" sx={{ ml: 1, width: 20, height: 20 }}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpenUploadDialog(true)}>
          Add Media
        </Button>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, overflowX: 'auto' }}>
            {renderApproveMediaTable()}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Media Sent Today
            </Typography>
            {renderMediaSentToday()}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Upload Media</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <input
                accept="image/*,video/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Select File
                </Button>
              </label>
              {file && <Typography>{file.name}</Typography>}
              <TextField
                margin="dense"
                label="Notes"
                type="text"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              <Autocomplete
                multiple
                options={studentOptions}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`} // Display full name in the dropdown
                onChange={(event, newValue) => setTaggedStudents(newValue)} // Update the tagged students on selection
                onInputChange={(event, newInputValue) => searchStudents(newInputValue)} // Call searchStudents on input change
                renderInput={(params) => <TextField {...params} label="Tag Students" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Tagged Students</Typography>
              {renderTaggedStudents()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleUpload} variant="contained" color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)} maxWidth="md" fullWidth>
        <DialogContent>
          <img src={selectedImage} alt="Full size" style={{ width: '100%', height: 'auto' }} />
        </DialogContent>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this media?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMediaDetailsDialog} onClose={() => setOpenMediaDetailsDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Media Details</DialogTitle>
        <DialogContent>
          {selectedMediaDetails && (
            <>
              <img
                src={getImageUrl(selectedMediaDetails.file_path)}
                alt={selectedMediaDetails.notes}
                style={{
                  width: '100%',
                  maxHeight: '50vh',
                  objectFit: 'contain',
                }}
              />
              <Typography variant="body1" sx={{ mt: 2 }}>
                Notes: {selectedMediaDetails.notes}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Tagged Students:{' '}
                {selectedMediaDetails.tagged_students
                  ? selectedMediaDetails.tagged_students
                      .split(',')
                      .map((student) => {
                        const [id, firstName, lastName] = student.split('|');
                        return `${firstName} ${lastName}`;
                      })
                      .join(', ')
                  : 'None'}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Upload Date: {new Date(selectedMediaDetails.upload_date).toLocaleString()}
              </Typography>
              {!selectedMediaDetails.approved && (
                <Button variant="contained" color="primary" onClick={handleApproveFromModal} sx={{ mt: 2 }}>
                  Approve
                </Button>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMediaDetailsDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Gallery;
