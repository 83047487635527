import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// pages imports
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Kit from './pages/Kit';
import UserChild from './pages/UserChild';
import Tasks from './pages/Tasks';
import Projects from './pages/Projects';
import Expenses from './pages/Expenses';
import Equipments from './pages/Equipments';
import Provider from './pages/Providers';
import Professionals from './pages/Professionals';
import Quotes from './pages/Quotes';
import Contract from './pages/Contract';
import Documents from './pages/Documents';

// New Pages
import Personal from './pages/Personal';
import Ispre from './pages/Isapre';
import AFP from './pages/AFP';
import Haber from './pages/Haber';
import ClasulasAnxo from './pages/ClausulasAnxo';
import TipoContrato from './pages/TipoContrato';
import Clausulas from './pages/Clausulas';
import Rut from './pages/Rut';

// PrivateRoute component import
import PrivateRoute from './PrivateRoute';
import EquipmentsDetails from './pages/EquipementsDetail';
import Payments from './pages/Payments';
// eslint-disable-next-line import/extensions
import Messages from './pages/Messages.js';
// eslint-disable-next-line import/extensions
import Report from './pages/Report.js';
// eslint-disable-next-line import/named
import Enrollment from './pages/Enrollment';
import Portfolio from './pages/Portfolio';
import Attendance from './pages/Attendance';
import Gallery from './pages/Gallery';
import Lessons from './pages/Lessons';

export default function Router({
  setAttemptToAddUser,
  setAttemptToEditUser,
  setAttemptToAddTask,
  setAttemptToEditTask,
  setAttemptToAddProject,
  setAttemptToEditProject,
  setAttemptToAddExpense,
  setAttemptToEditExpense,
  setAttemptToAddEquipmentDetails,
  setAttemptToEditEquipmentDetails,
  setAttemptToAddContract,
  setAttemptToEditContract,
  setAttemptToAddDocument,
  setAttemptToEditDocument,
  setAttemptToAddPersonal,
  setAttemptToEditPersonal,
  setAttemptToAddIspre,
  setAttemptToEditIspre,
  setAttemptToAddAFP,
  setAttemptToEditAFP,
  setAttemptToAddHaber,
  setAttemptToEditHaber,
  setAttemptToAddClausulasAnxo,
  setAttemptToEditClausulasAnxo,
  setAttemptToAddTipoContrato,
  setAttemptToEditTipoContrato,
  setAttemptToAddClausulas,
  setAttemptToEditClausulas,
  setAttemptToAddCentro,
  setAttemptToEditCentro,
  setAttemptToAddMantenedor,
  setAttemptToEditMantenedor,
  setAttemptToAddLicencias,
  setAttemptToEditLicencias,
  setAttemptToAddHistorico,
  setAttemptToEditHistorico,
}) {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> }, // Redirect root to /dashboard
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'kit', element: <Kit /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        { path: 'dashboard', element: <DashboardApp /> }, // Same component for /dashboard
        { path: '/', element: <DashboardApp /> }, // Same component for root ("/")

        {
          path: 'users',
          element: (
            <PrivateRoute>
              <User setAttemptToAddUser={setAttemptToAddUser} setAttemptToEditUser={setAttemptToEditUser} />
            </PrivateRoute>
          ),
        },
        {
          path: 'users/:id',
          element: (
            <PrivateRoute>
              <UserChild />
            </PrivateRoute>
          ),
        },
        { path: 'buy', element: <Products /> },
        { path: 'equipment', element: <Equipments /> },
        {
          path: 'expenses',
          element: (
            <PrivateRoute>
              <Expenses
                setAttemptToAddExpense={setAttemptToAddExpense}
                setAttemptToEditExpense={setAttemptToEditExpense}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'proyectos',
          element: (
            <PrivateRoute>
              <Projects
                setAttemptToAddProject={setAttemptToAddProject}
                setAttemptToEditProject={setAttemptToEditProject}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'attendance',
          element: (
            <PrivateRoute>
              <Attendance setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'gallery',
          element: (
            <PrivateRoute>
              <Gallery setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'lessons',
          element: (
            <PrivateRoute>
              <Lessons setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'billing',
          element: (
            <PrivateRoute>
              <Payments setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'messages',
          element: (
            <PrivateRoute>
              <Messages setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'report',
          element: (
            <PrivateRoute>
              <Report setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'portfolio',
          element: (
            <PrivateRoute>
              <Portfolio setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'enrollment',
          element: (
            <PrivateRoute>
              <Enrollment setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'tareas',
          element: (
            <PrivateRoute>
              <Tasks setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        { path: 'blog', element: <Blog /> },
        { path: 'provider', element: <Provider /> },
        { path: 'professional', element: <Professionals /> },
        { path: 'quotes', element: <Quotes /> },
        {
          path: 'document',
          element: (
            <PrivateRoute>
              <Documents
                setAttemptToAddDocument={setAttemptToAddDocument}
                setAttemptToEditDocument={setAttemptToEditDocument}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'contract',
          element: (
            <PrivateRoute>
              <Contract
                setAttemptToAddContract={setAttemptToAddContract}
                setAttemptToEditContract={setAttemptToEditContract}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'equipment/equipmentDetails',
          element: (
            <PrivateRoute>
              <EquipmentsDetails
                setAttemptToAddEquipmentDetails={setAttemptToAddEquipmentDetails}
                setAttemptToEditEquipmentDetails={setAttemptToEditEquipmentDetails}
              />
            </PrivateRoute>
          ),
        },
        // New routes
        {
          path: 'personal',
          element: (
            <PrivateRoute>
              <Personal
                setAttemptToAddPersonal={setAttemptToAddPersonal}
                setAttemptToEditPersonal={setAttemptToEditPersonal}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'ispre',
          element: (
            <PrivateRoute>
              <Ispre setAttemptToAddIspre={setAttemptToAddIspre} setAttemptToEditIspre={setAttemptToEditIspre} />
            </PrivateRoute>
          ),
        },
        {
          path: 'afp',
          element: (
            <PrivateRoute>
              <AFP setAttemptToAddAFP={setAttemptToAddAFP} setAttemptToEditAFP={setAttemptToEditAFP} />
            </PrivateRoute>
          ),
        },
        {
          path: 'haber',
          element: (
            <PrivateRoute>
              <Haber setAttemptToAddHaber={setAttemptToAddHaber} setAttemptToEditHaber={setAttemptToEditHaber} />
            </PrivateRoute>
          ),
        },
        {
          path: 'clasulasanxo',
          element: (
            <PrivateRoute>
              <ClasulasAnxo
                setAttemptToAddClausulasAnxo={setAttemptToAddClausulasAnxo}
                setAttemptToEditClausulasAnxo={setAttemptToEditClausulasAnxo}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'tipodecontrato',
          element: (
            <PrivateRoute>
              <TipoContrato
                setAttemptToAddTipoContrato={setAttemptToAddTipoContrato}
                setAttemptToEditTipoContrato={setAttemptToEditTipoContrato}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'clasulas',
          element: (
            <PrivateRoute>
              <Clausulas
                setAttemptToAddClausulas={setAttemptToAddClausulas}
                setAttemptToEditClausulas={setAttemptToEditClausulas}
              />
            </PrivateRoute>
          ),
        },
        {
          path: 'personal/rut',
          element: (
            <PrivateRoute>
              <Rut
                setAttemptToAddCentro={setAttemptToAddCentro}
                setAttemptToEditCentro={setAttemptToEditCentro}
                setAttemptToAddMantenedor={setAttemptToAddMantenedor}
                setAttemptToEditMantenedor={setAttemptToEditMantenedor}
                setAttemptToAddLicencias={setAttemptToAddLicencias}
                setAttemptToEditLicencias={setAttemptToEditLicencias}
                setAttemptToAddHistorico={setAttemptToAddHistorico}
                setAttemptToEditHistorico={setAttemptToEditHistorico}
              />
            </PrivateRoute>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
