import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// @mui
import {
  Stack,
  Divider,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Button,
  Chip,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  CircularProgress, // Import CircularProgress for loader
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import apis from 'src/services/api';
// eslint-disable-next-line import/no-unresolved
import Iconify from '../components/Iconify';
import MenuPopover from '../components/MenuPopover';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

// Helper function to determine payment status
const getPaymentStatus = (requestDate) => {
  const currentDate = new Date();
  const due = new Date(requestDate);

  if (due < currentDate) {
    return 'Overdue';
  }
  return 'Pending';
};

export default function BillingManagement() {
  const [activeTab, setActiveTab] = useState(0);
  const [studentPaymentData, setStudentPaymentData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [userHistory, setUserHistory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false); // Loader state
  const [selectedStudents, setSelectedStudents] = useState([]); // Track selected students
  const [paymentAmount, setPaymentAmount] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false); // Track whether to show the payment form

  // Fetch student data
  const getStudentData = async () => {
    setLoading(true); // Show loader when making API call
    try {
      const response = await apis.getStudentData();
      setStudentData(response?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Hide loader after data is fetched
    }
  };

  // Fetch student payment data
  const getStudentPaymentData = async () => {
    setLoading(true); // Show loader when making API call
    try {
      const response = await apis.getStudentPaymentData();
      setStudentPaymentData(response?.data?.billingRequests);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Hide loader after data is fetched
    }
  };

  // Fetch active users
  const getActiveUsers = async () => {
    setLoading(true); // Show loader when making API call
    try {
      const response = await apis.getActiveUsers();
      setActiveUsers(response?.data?.users);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Hide loader after data is fetched
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      getStudentPaymentData();
    } else if (activeTab === 1) {
      getActiveUsers();
    }
    getStudentPaymentData(); // Always fetch student data
    getStudentData();
  }, [activeTab]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectStudent = (studentId) => {
    // Toggle selection of student
    setSelectedStudents(
      (prevSelected) =>
        prevSelected.includes(studentId)
          ? prevSelected.filter((id) => id !== studentId) // Remove student if already selected
          : [...prevSelected, studentId] // Add student if not selected
    );
  };

  const handleGeneratePaymentRequest = () => {
    // Toggle the show payment form state to display payment details for selected students
    setShowPaymentForm(true);
  };

  const handleSubmitPaymentRequest = () => {
    // Prepare the payment request data for the selected students
    const payments = selectedStudents.map((studentId) => ({
      studentId,
      paymentAmount,
      dueDate,
    }));

    console.log('Generated Payments:', payments);
    // Example API call for generating the payment request
    // apis.generatePaymentRequest(payments);
  };

  const handleCancelPaymentRequest = () => {
    // Reset selected students, payment amount, and due date, and hide the payment form
    setSelectedStudents([]);
    setPaymentAmount('');
    setDueDate('');
    setShowPaymentForm(false);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h3" paragraph>
        Billing Management
      </Typography>

      {/* Tabs for switching between Requested and Active lists */}
      <Tabs
        value={activeTab}
        direction="column"
        onChange={handleTabChange}
        sx={{ mb: 2, display: 'flex', flexDirection: 'row' }}
      >
        <Tab label="Requested" />
        <Tab label="Active" />
        <Tab label="Inactive" />
        <Tab label="Report" />
      </Tabs>

      {/* Loader */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Requested Students */}
      {activeTab === 0 && !loading && !showPaymentForm && (
        <>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Button variant="contained" onClick={handleGeneratePaymentRequest}>
              Generate Payment Request
            </Button>
          </Stack>

          <Stack direction="column" spacing={2}>
            {/* Requested List (Pending Payments) */}
            {studentPaymentData?.map((task) => (
              <Stack
                direction="row"
                sx={{
                  px: 2,
                  py: 1,
                  backgroundColor: '#f9f9f9',
                  borderRadius: '14px',
                  mb: 2,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={task.id}
              >
                <Box sx={{ width: '15%' }}>
                  <Typography variant="body1" sx={{ color: '#307FE2' }}>
                    ${task.billing_amount}
                  </Typography>
                </Box>
                <Box sx={{ width: '30%' }}>
                  <Typography variant="body1">{task.student_name}</Typography>
                </Box>
                <Box sx={{ width: '25%' }}>
                  <Typography variant="body1">{formatDate(task.request_date)}</Typography>
                </Box>
                <Box sx={{ width: '15%' }}>
                  <Chip
                    label={getPaymentStatus(task.request_date)}
                    color={getPaymentStatus(task.request_date) === 'Overdue' ? 'error' : 'warning'}
                  />
                </Box>
              </Stack>
            ))}
          </Stack>
        </>
      )}

      {/* Show payment form when "Generate Payment Request" is clicked */}
      {showPaymentForm && (
        <>
          <Stack direction="column" spacing={2}>
            {/* Requested List (Pending Payments) */}
            {studentData?.map((task) => (
              <Stack
                direction="row"
                sx={{
                  px: 2,
                  py: 1,
                  backgroundColor: '#f9f9f9',
                  borderRadius: '14px',
                  mb: 2,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={task.id}
              >
                <Box sx={{ width: '5%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedStudents.includes(task.id)}
                        onChange={() => handleSelectStudent(task.id)}
                      />
                    }
                  />
                </Box>
                <Box sx={{ width: '65%' }}>
                  <Typography variant="body1">{task?.first_name + task?.last_name}</Typography>
                </Box>
                <Box sx={{ width: '28%' }}>
                  <Typography variant="body1">{task.classroom_name}</Typography>
                </Box>
              </Stack>
            ))}
          </Stack>

          <Typography variant="h5" sx={{ mb: 2 }}>
            Enter Payment Details
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Payment Amount"
              type="number"
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Due Date"
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Stack>

          {/* Submit and Cancel Buttons */}
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={handleSubmitPaymentRequest} sx={{ mt: 2 }}>
              Submit Payment Request
            </Button>
            <Button variant="outlined" onClick={handleCancelPaymentRequest} sx={{ mt: 2 }}>
              Cancel
            </Button>
          </Stack>
        </>
      )}

      {/* Active Users List */}
      {activeTab === 1 && !loading && (
        <>
          <TextField
            value={searchQuery}
            onChange={handleSearchChange}
            label="Search Active Users"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">{/* <Search /> */}</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />

          <Stack direction="column" spacing={2}>
            {activeUsers
              .filter((user) => user.name.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((user) => (
                <Stack
                  direction="row"
                  sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: '#f9f9f9',
                    borderRadius: '14px',
                    mb: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  key={user.id}
                >
                  <Box sx={{ width: '50%' }}>
                    <Typography variant="body1">{user.name}</Typography>
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <Button variant="contained" onClick={() => setUserHistory(user.paymentHistory)}>
                      View History
                    </Button>
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <Button variant="outlined" onClick={() => console.log('Inactivate User', user)}>
                      Inactivate
                    </Button>
                  </Box>
                </Stack>
              ))}
          </Stack>
        </>
      )}
    </Box>
  );
}
