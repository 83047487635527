import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Avatar,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { HelpOutline as HelpIcon } from '@mui/icons-material';

// Mock data for the enrollment pipeline
const pipelineData = {
  interested: 5,
  touring: 3,
  visited: 2,
  waitlist: 4,
  scheduled: 1,
  active: 9,
  disenrolling: 1,
  inactive: 2,
};

// Mock data for students in different pipeline stages
const studentsData = {
  interested: [
    { id: 1, name: 'John Doe', age: 4, parentName: 'Jane Doe' },
    { id: 2, name: 'Alice Smith', age: 3, parentName: 'Bob Smith' },
  ],
  touring: [{ id: 3, name: 'Emma Johnson', age: 5, parentName: 'Michael Johnson' }],
  // ... add more mock data for other pipeline stages
};

const Enrollment = () => {
  const [selectedStage, setSelectedStage] = useState('interested');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [note, setNote] = useState('');

  const handleStageChange = (event, newValue) => {
    setSelectedStage(newValue);
    setSelectedStudent(null);
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  const handleSendNote = () => {
    console.log(`Sending note to ${selectedStudent.parentName}: ${note}`);
    setNote('');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Enrollment Pipeline
        </Typography>
        <Tooltip title="Manage the enrollment process, from initial interest to active enrollment.">
          <IconButton size="small" sx={{ ml: 1, width: 20, height: 20 }}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Tabs Section */}
      <Paper
        elevation={6}
        sx={{ p: 2, mb: 2 }}
        style={{
          display: 'flex !important', // Force tabs to be horizontal
          overflowX: 'auto !important', // Ensure horizontal scrolling is possible
          flexWrap: 'nowrap !important', // Prevent tabs from wrapping to next line
        }}
      >
        <Tabs
          value={selectedStage}
          onChange={handleStageChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="enrollment pipeline tabs"
          style={{
            display: 'flex !important', // Force tabs to be horizontal
            overflowX: 'auto !important', // Ensure horizontal scrolling is possible
            flexWrap: 'nowrap !important', // Prevent tabs from wrapping to next line
          }}
        >
          {Object.entries(pipelineData).map(([stage, count]) => (
            <Tab
              key={stage}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>{count}</Avatar>
                  <Typography variant="caption" sx={{ textAlign: 'center' }}>
                    {stage.charAt(0).toUpperCase() + stage.slice(1)}
                  </Typography>
                </Box>
              }
              value={stage}
            />
          ))}
        </Tabs>
      </Paper>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Students List */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: 600, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Students - {selectedStage.charAt(0).toUpperCase() + selectedStage.slice(1)}
            </Typography>
            <List>
              {studentsData[selectedStage]?.map((student) => (
                <ListItem
                  key={student.id}
                  button
                  onClick={() => handleStudentClick(student)}
                  selected={selectedStudent?.id === student.id}
                >
                  <ListItemText
                    primary={student.name}
                    secondary={`Age: ${student.age} | Parent: ${student.parentName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Send Note Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Send Note to Parent
            </Typography>
            {selectedStudent ? (
              <>
                <Typography variant="body1" gutterBottom>
                  To: {selectedStudent.parentName} (Parent of {selectedStudent.name})
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" color="primary" onClick={handleSendNote}>
                  Send Note
                </Button>
              </>
            ) : (
              <Typography variant="body1">Select a student from the list to send a note to their parent.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Enrollment;
