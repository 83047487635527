import axios from 'axios';

const createBackendServer = () => {
  const api = axios.create({
    baseURL: 'https://tinytots-backend.pixeligator.com/api/', // Hardcoded URL
    withCredentials: false,
    headers: {
      Accept: 'application/json',
    },
    timeout: 60 * 1000, // Timeout of 1 minute
  });

  // Request interceptor
  api.interceptors.request.use(
    (config) => {
      const userToken = localStorage.getItem('authToken');
      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data?.message;
      error.message = message ?? error.message;
      return Promise.reject(error);
    }
  );

  // Login function
  const login = (body) => api.post('login', body);

  // get student data
  const getStudentData = () => api.get('portfolio/students');

  // get student data
  const getStudents = (classroomId) => api.get(`portfolio/students?classroomId=${classroomId}`);

  // get student data
  const getStudentProfile = (studentId) => api.get(`portfolio/students/${studentId}`);

  // get parent data
  const getParentData = () => api.get('parents/get-all');

  // get staff data
  const getStaff = () => api.get('staff');

  // get student payment data
  const getStudentPaymentData = () => api.get('billingrequests/all');

  // billing request
  const billingRequest = (body) => api.post('billingrequests', body);

  // Classroom services
  const classroomService = () => api.get('classrooms');

  // Classroom services
  const incidents = (selectedClassroom) => api.get(`portfolio/incidents?classroomId=${selectedClassroom}`);

  // lesson

  // eslint-disable-next-line camelcase
  const fetchLessons = (start_date, end_date) => api.get(`lessons/details`, start_date, end_date);

  // Classroom services
  // eslint-disable-next-line camelcase
  const fetchLessonDetails = (classroom_id, date) => api.get(`lessons/details`, classroom_id, date);

  // send message services
  const sendMessage = (body) => api.post('messages/sent', body);

  return {
    login,

    getStudentData,
    getStudents,
    getStudentProfile,
    getParentData,
    getStudentPaymentData,
    billingRequest,
    classroomService,
    sendMessage,
    incidents,
    fetchLessonDetails,
    fetchLessons,
    getStaff,
  };
};

// Create the API instance
const apis = createBackendServer(); // No need for baseURL here
export default apis;
