import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../../components/Iconify';

// Styled components
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// Component
export default function UserManagement() {
  const [attemptToAddUser, setAttemptToAddUser] = useState(null); // 'open' to show modal
  const [filterName, setFilterName] = useState(''); // Search filter state
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleFilterName = (event) => {
    setFilterName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username || !email || !password || !confirmPassword) {
      setError('All fields are required!');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    setLoading(true);
    setError(''); // Reset error message before making request
    try {
      // Simulate user creation (replace with your API call)
      console.log('Creating User:', { username, email, password });

      // After successful creation, close the modal
      setLoading(false);
      setAttemptToAddUser(null);
      alert('User created successfully!');
    } catch (error) {
      setLoading(false);
      setError('Error creating user');
    }
  };

  return (
    <div>
      {/* UserListToolbar */}
      <RootStyle>
        <Typography component="div" variant="h6">
          Users
        </Typography>

        <SearchStyle
          value={filterName}
          onChange={handleFilterName}
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />

        <Button
          variant="contained"
          onClick={() => setAttemptToAddUser('open')}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Users
        </Button>
      </RootStyle>

      {/* Add User Modal */}
      <Dialog open={attemptToAddUser === 'open'} onClose={() => setAttemptToAddUser(null)}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {error && (
              <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <TextField
              label="Username"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
              type="email"
            />
            <TextField
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              margin="normal"
              type="password"
            />
            <TextField
              label="Confirm Password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              margin="normal"
              type="password"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttemptToAddUser(null)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={loading}
            sx={{
              display: 'flex',
              background: 'primary',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 120,
            }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
