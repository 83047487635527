// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon('bi:house-door-fill'),
  },
  {
    title: 'Attendance',
    path: '/attendance',
    icon: getIcon('fa6-solid:clipboard-list'),
  },
  {
    title: 'Gallery',
    path: '/gallery',
    icon: getIcon('fa6-solid:image'),
  },
  {
    title: 'Lessons',
    path: '/lessons',
    icon: getIcon('fa6-solid:graduation-cap'),
  },
  {
    title: 'Daily Reports',
    path: '/report',
    icon: getIcon('fa6-solid:rectangle-list'),
  },
  {
    title: 'Enrollment',
    path: '/enrollment',
    icon: getIcon('fa6-solid:address-card'),
  },
  {
    title: 'Users',
    path: '/users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Messages',
    path: '/messages',
    icon: getIcon('fa6-solid:message'),
  },
  {
    title: 'Portfolio',
    path: '/portfolio',
    icon: getIcon('fa6-solid:image-portrait'),
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: getIcon('fa-solid:credit-card'),
  },
  // {
  //   title: 'Tareas',
  //   path: '/tareas',
  //   icon: getIcon('fa-solid:map-pin'),
  // },
  // {
  //   title: 'Proyectos',
  //   path: '/proyectos',
  //   icon: getIcon('akar-icons:file'),
  // },
  // {
  //   title: 'Comprar',
  //   path: '/buy',
  //   icon: getIcon('fa:product-hunt'),
  // },
  // {
  //   title: 'Proveedores',
  //   path: '/provider',
  //   icon: getIcon('fa:qq'),
  // },
  // {
  //   title: 'Profesionales',
  //   path: '/professional',
  //   icon: getIcon('fa:gg'),
  // },
  // {
  //   title: 'Gustos',
  //   path: '/expenses',
  //   icon: getIcon('fa:bandcamp'),
  // },
  // {
  //   title: 'Equipos',
  //   path: '/equipment',
  //   icon: getIcon('fa:gg-circle'),
  // },
  // {
  //   title: 'Cotizaciones',
  //   path: '/quotes',
  //   icon: getIcon('fa:scribd'),
  // },
  // {
  //   title: 'Contratos',
  //   path: '/contract',
  //   icon: getIcon('fa:book'),
  // },
  // {
  //   title: 'Documentos',
  //   path: '/document',
  //   icon: getIcon('fa:file'),
  // },
  // {
  //   title: 'Blog',
  //   path: '/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Personal',
  //   path: '/personal',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Ispre',
  //   path: '/ispre',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'AFP',
  //   path: '/afp',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Haber',
  //   path: '/haber',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Clasulas Anxe',
  //   path: '/clasulasanxe',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Tipo de Contrato',
  //   path: '/tipodecontrato',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Clasulas',
  //   path: '/clasulas',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'new',
  //   path: '/new',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'Iniciar Sesión',
  //   path: '/iniciar',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
