// import { faker } from '@faker-js/faker';
// // @mui
// import { useTheme } from '@mui/material/styles';
// import { Grid, Container, Typography } from '@mui/material';
// // components
// import Page from '../components/Page';
// import Iconify from '../components/Iconify';
// // sections
// import {
//   AppUsersConected,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppWidgetSummary,
//   CustomDay as AppCalendar,
// } from '../sections/@dashboard/app';

// export default function DashboardApp() {
//   const theme = useTheme();

//   return (
//     <Page title="Dashboard">
//       <Container maxWidth="xl">
//         <Typography variant="h3" sx={{ mb: 5 }}>
//           Panel Principal - Dashboard
//         </Typography>

//         <Grid container spacing={3}>
//           <Grid item xs={12} sm={6} md={3}>
//             <AppWidgetSummary title="Tareas Nuevas" total={10} icon={'akar-icons:star'} />
//           </Grid>

//           <Grid item xs={12} sm={6} md={3}>
//             <AppWidgetSummary title="Tareas Finalizadas" total={17} icon={'ant-design:star-filled'} />
//           </Grid>

//           <Grid item xs={12} sm={6} md={3}>
//             <AppWidgetSummary title="Proyectos Nuevos" total={6} icon={'ant-design:file-filled'} />
//           </Grid>

//           <Grid item xs={12} sm={6} md={3}>
//             <AppWidgetSummary title="Proyectos Hechos" total={2} icon={'akar-icons:file'} />
//           </Grid>

//           <Grid item xs={12} md={6} lg={8}>
//             <AppWebsiteVisits
//               title="Projectos finalizados"
//               subheader="(+43%)"
//               chartLabels={[
//                 '01/01/2003',
//                 '02/01/2003',
//                 '03/01/2003',
//                 '04/01/2003',
//                 '05/01/2003',
//                 '06/01/2003',
//                 '07/01/2003',
//                 '08/01/2003',
//                 '09/01/2003',
//                 '10/01/2003',
//                 '11/01/2003',
//               ]}
//               chartData={[
//                 {
//                   name: 'Team A',
//                   type: 'column',
//                   fill: 'solid',
//                   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
//                 },
//                 {
//                   name: 'Team B',
//                   type: 'area',
//                   fill: 'gradient',
//                   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
//                 },
//                 {
//                   name: 'Team C',
//                   type: 'line',
//                   fill: 'solid',
//                   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
//                 },
//               ]}
//             />
//           </Grid>

//           <Grid item xs={12} md={6} lg={4}>
//             <AppCurrentVisits
//               title="Proyectos"
//               chartData={[
//                 { label: 'Faltantes', value: 4344 },
//                 { label: 'Completados', value: 7435 },
//               ]}
//               chartColors={[theme.palette.primary.main, theme.palette.chart.green[0]]}
//             />
//           </Grid>

//           <Grid item xs={12} md={6} lg={8}>
//             <AppUsersConected
//               title="Usuarios en linea"
//               list={[...Array(5)].map((_, index) => ({
//                 id: faker.datatype.uuid(),
//                 title: faker.name.firstName(),
//                 description: faker.name.jobTitle(),
//                 image: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
//                 postedAt: faker.date.recent(),
//               }))}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <AppCalendar />
//           </Grid>

//           <Grid item xs={12} md={6} lg={4}>
//             <AppOrderTimeline
//               title="Actividad reciente"
//               list={[...Array(5)].map((_, index) => ({
//                 id: faker.datatype.uuid(),
//                 title: [
//                   'Fernando inicio sesion',
//                   'Eduardo Gomez creo un nuevo proyecto',
//                   'Yoselin Avila acaba de finalizar una tarea',
//                   'Nohemi modifico dos proyecto',
//                   'Nohemi modifico un proyecto',
//                 ][index],
//                 type: `order${index + 1}`,
//               }))}
//             />
//           </Grid>
//         </Grid>
//       </Container>
//     </Page>
//   );
// }

import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { format } from 'date-fns';
import dashboardData from './data/dashboardData.json';
import apis from '../services/api';

const Dashboard = () => {
  const { attendance, activities, overview, classRatios } = dashboardData;
  const [openAnnouncementDialog, setOpenAnnouncementDialog] = useState(false);
  const [messageType, setMessageType] = useState('');
  const [recipient, setRecipient] = useState('');
  const [message, setMessage] = useState('');
  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const response = await apis.classroomService();
        setClassrooms(response.data);
      } catch (err) {
        console.error('Error fetching classrooms:', err);
      }
    };

    fetchClassrooms();
  }, []);

  const handleOpenAnnouncementDialog = () => {
    setOpenAnnouncementDialog(true);
  };

  const handleCloseAnnouncementDialog = () => {
    setOpenAnnouncementDialog(false);
    // Reset form fields
    setMessageType('');
    setRecipient('');
    setMessage('');
  };

  const handleSubmitAnnouncement = () => {
    // Here you would typically send this data to your backend
    console.log({ messageType, recipient, message });
    handleCloseAnnouncementDialog();
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleOpenAnnouncementDialog}>
        Announcement
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 400,
              overflowY: 'auto',
              // Custom scrollbar styling
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main', // Theme primary color
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'primary.dark', // Darker shade on hover
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', // Track background
              },
            }}
          >
            <Typography variant="h6" gutterBottom>
              Activities
            </Typography>
            <Typography variant="body2" gutterBottom>
              {activities.description}
            </Typography>
            <List>
              {activities.data.map((activity, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={activity.name}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            {format(new Date(activity.date), 'MMMM d, yyyy')}
                          </Typography>
                          {` — ${activity.description}`}
                        </>
                      }
                    />
                  </ListItem>
                  {index < activities.data.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" gutterBottom>
              Overview
            </Typography>
            <Typography variant="body1">{overview.description}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: 400, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Attendance
            </Typography>
            <Typography variant="body2" gutterBottom>
              {attendance.description}
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={attendance.data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {attendance.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Class Ratios
            </Typography>
            <TableContainer sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {classRatios.map((classInfo) => (
                      <TableCell key={classInfo.name} align="center">
                        {classInfo.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Teachers
                    </TableCell>
                    {classRatios.map((classInfo) => (
                      <TableCell key={`${classInfo.name}-teachers`} align="center">
                        {classInfo.teachers}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Students
                    </TableCell>
                    {classRatios.map((classInfo) => (
                      <TableCell key={`${classInfo.name}-students`} align="center">
                        {classInfo.students}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* Announcement Dialog */}
      <Dialog open={openAnnouncementDialog} onClose={handleCloseAnnouncementDialog}>
        <DialogTitle>Make Announcement</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
            <InputLabel>Message Type</InputLabel>
            <Select value={messageType} label="Message Type" onChange={(e) => setMessageType(e.target.value)}>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="note">Note</MenuItem>
              <MenuItem value="emergency">Emergency</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Recipient"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAnnouncementDialog}>Cancel</Button>
          <Button onClick={handleSubmitAnnouncement} variant="contained" color="primary">
            Send Announcement
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Dashboard;
