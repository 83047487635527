// frontend/src/labels/englishLabels.js

const englishLabels = {
    attendance: {
        title: "Attendance",
        checkIn: "Check In",
        checkOut: "Check Out",
        notAttending: "Not Attending",
        addChild: "Add Child",
        addStaff: "Add Staff",
        addClassroom: "Add Classroom",
        reports: "Reports",
        totalPresent: "Total Present",
        more: "More",
    },
    gallery: {
        title: "Gallery",
        manageMedia: "Manage and view photos and videos from various school activities.",
        uploadMedia: "Upload Media",
        approveMedia: "Approve Media",
        mediaSentToday: "Media Sent Today",
        confirmDeletion: "Are you sure you want to delete this media?",
        // Add more labels as needed
    },
    lessons: {
        title: "Lessons",
        addLesson: "Add Lesson",
        dailyCalendar: "Daily Calendar",
        lessonDetails: "Lesson Details",
        selectClassrooms: "Select Classrooms",
        addNewLesson: "Add New Lesson",
        selectAllClassrooms: "Select All Classrooms",
        classrooms: "Classrooms",
        teacher: "Teacher (Optional)",
        subject: "Subject",
        date: "Date",
        startTime: "Start Time",
        endTime: "End Time",
        description: "Description",
        cancel: "Cancel",
        save: "Add Lesson"
    },
    portfolio: {
        title: "Portfolio",
        tooltip: "View and manage student portfolios, including incidents and achievements.",
        addChild: "Add Child",
        addStaff: "Add Staff",
        addClassroom: "Add Classroom",
        students: "Students",
        incidents: "Incidents",
        addIncident: "Add Incident",
        noIncidents: "No incidents reported.",
        searchExistingParent: "Search Existing Parent",
        selectedParent: "Selected Parent",
        remove: "Remove",
        firstName: "First Name",
        lastName: "Last Name",
        dateOfBirth: "Date of Birth",
        classroom: "Classroom",
        cancel: "Cancel",
        addChildButton: "Add Child",
        role: "Role",
        email: "Email",
        accessAllClassrooms: "Access to all classrooms",
        assignedClassrooms: "Assigned Classrooms",
        addStaffButton: "Add Staff",
        classroomName: "Classroom Name",
        capacity: "Capacity",
        addClassroomButton: "Add Classroom",
        profile: "Profile",
        edit: "Edit",
        selectStudent: "Select a student to view their profile",
    },
    messages: {
        title: "Messages",
        tooltip: "Send and manage messages to parents, staff, and other stakeholders.",
        composeMessage: "Compose Message",
        messageType: "Message Type",
        text: "Text",
        email: "Email",
        note: "Note",
        emergency: "Emergency",
        recipient: "Recipient",
        message: "Message",
        sendMessage: "Send Message",
        messagesSentToday: "Messages Sent Today",
        parentsMessaging: "Parents Messaging",
        massMessaging: "Mass Messaging",
        allParents: "All Parents",
        allStaff: "All Staff",
        sentAt: "Sent at",
        content: "Content",
    },
    // Add other components' labels here as needed
};

export default englishLabels;