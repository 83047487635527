/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Chip,
} from '@mui/material';
import api from 'src/services/api'; // Assuming the API service is imported here
import { styled } from '@mui/system';

// Styled components for reusable styling
const ClassroomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: 56,
  height: 56,
}));

// Styled components for reusable styling
const ClassroomChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  fontSize: '16px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const StudentCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  borderRadius: theme.shape.borderRadius,
}));

const StudentAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

const ReportContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ClassroomReport = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState('ALL');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editableStudent, setEditableStudent] = useState(null); // Local state for editable fields
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode

  // Fetch classrooms from API using classroomService (from api.js)
  const getClassrooms = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.classroomService(); // Fetch classrooms from api.js
      setClassrooms(response?.data || []);
    } catch (err) {
      setError('Error fetching classrooms');
    } finally {
      setLoading(false);
    }
  };

  // Fetch students based on selected classroom
  const getStudents = async (classroomId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudents(classroomId); // Pass classroomId to API
      setStudents(response?.data || []);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  // Fetch selected student's profile
  const getStudentProfile = async (studentId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudentProfile(studentId);
      const student = response?.data || null;
      setSelectedStudent(response?.data || null);

      setEditableStudent({ ...student }); // Initialize editable state with fetched data
    } catch (err) {
      setError('Error fetching student profile');
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setEditableStudent((prev) => ({ ...prev, [field]: value }));
  };
  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
  };
  // Handle classroom selection change
  const handleClassroomChange = (classroomId) => {
    setSelectedClassroom(classroomId);
  };

  // Handle student card click
  const handleStudentClick = (student) => {
    getStudentProfile(student.id);
  };

  // Handle print report
  const handlePrintReport = () => {
    const content = document.getElementById('student-report-content');
    const printWindow = window.open('_blank', '_blank');
    printWindow.document.write(content.innerHTML);
    printWindow.document.close();
    printWindow.print();
  };

  // Fetch classrooms and students on component mount or when selectedClassroom changes
  useEffect(() => {
    getClassrooms();
  }, []);

  useEffect(() => {
    if (selectedClassroom) {
      // Fetch students when the classroom selection changes
      getStudents(selectedClassroom);
    }
  }, [selectedClassroom]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h3" sx={{ marginBottom: 2 }} paragraph>
        Daiy Reports
      </Typography>

      {/* Classroom Selection Section */}
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Select Classroom
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
          <ClassroomChip
            label="ALL"
            onClick={() => handleClassroomChange('ALL')}
            className={selectedClassroom === 'ALL' ? 'selected' : ''}
          />
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography sx={{ color: 'error.main', textAlign: 'center', marginTop: 4 }}>{error}</Typography>
          ) : classrooms.length === 0 ? (
            <Typography sx={{ textAlign: 'center', marginTop: 4 }}>No classrooms available</Typography>
          ) : (
            classrooms.map((classroom) => (
              <ClassroomChip
                key={classroom.id}
                label={classroom.name}
                onClick={() => handleClassroomChange(classroom.id)}
                className={selectedClassroom === classroom.id ? 'selected' : ''}
              />
            ))
          )}
        </Box>
      </Paper>

      {/* Students List Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 600,
              overflowY: 'auto',
              // Custom scrollbar styling
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main', // Theme primary color
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'primary.dark', // Darker shade on hover
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', // Track background
              },
            }}
          >
            <Typography variant="h6" gutterBottom>
              Students
            </Typography>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography sx={{ color: 'error.main', textAlign: 'center', marginTop: 4 }}>{error}</Typography>
            ) : students.length === 0 ? (
              <Typography>No students found.</Typography>
            ) : (
              students.map((student) => (
                <StudentCard key={student.id} onClick={() => handleStudentClick(student)}>
                  <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <StudentAvatar>
                      {student.first_name?.[0]}
                      {student.last_name?.[0]}
                    </StudentAvatar>
                    <Typography>{`${student.first_name} ${student.last_name}`}</Typography>
                  </CardContent>
                </StudentCard>
              ))
            )}
          </Paper>
        </Grid>

        {/* Student Report Section */}
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 2, height: 600, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Student Report</Typography>
              {selectedStudent && (
                <Box>
                  <Button variant="outlined" sx={{ marginRight: 2, marginTop: 2 }} onClick={toggleEditMode}>
                    {isEditing ? 'View' : 'Edit'}
                  </Button>
                  <Button variant="outlined" sx={{ marginTop: 2 }} onClick={handlePrintReport}>
                    Print
                  </Button>
                </Box>
              )}
            </Box>

            {editableStudent ? (
              <ReportContent id="student-report-content">
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={editableStudent.profile_picture}
                    sx={{ width: 60, height: 60, mr: 2, borderRadius: '50%' }}
                  />
                  <Typography variant="h6">{`${editableStudent.first_name} ${editableStudent.last_name}`}</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />

                {/* Editable fields */}
                <Typography variant="h6">Notes: </Typography>
                {isEditing ? (
                  <input
                    type="text"
                    style={{ fontSize: '18px', padding: '5px', width: '100%' }}
                    value={editableStudent.notes || ''}
                    onChange={(e) => handleFieldChange('notes', e.target.value)}
                  />
                ) : (
                  <Typography>{editableStudent.notes || 'Add notes...'}</Typography>
                )}
                <Typography variant="h6">Naps Meals: </Typography>
                {isEditing ? (
                  <input
                    type="text"
                    style={{ fontSize: '18px', padding: '5px', width: '100%' }}
                    value={editableStudent.naps_meals || ''}
                    onChange={(e) => handleFieldChange('naps_meals', e.target.value)}
                  />
                ) : (
                  <Typography>{editableStudent.naps_meals || 'Add naps meals...'}</Typography>
                )}
                <Typography variant="h6">Diapers: </Typography>
                {isEditing ? (
                  <input
                    type="text"
                    style={{ fontSize: '18px', padding: '5px', width: '100%' }}
                    value={editableStudent.diapers || ''}
                    onChange={(e) => handleFieldChange('diapers', e.target.value)}
                  />
                ) : (
                  <Typography>{editableStudent.diapers || 'Add diapers...'}</Typography>
                )}
                <Typography variant="h6">Bathroom: </Typography>
                {isEditing ? (
                  <input
                    type="text"
                    style={{ fontSize: '18px', padding: '5px', width: '100%' }}
                    value={editableStudent.bathroom || ''}
                    onChange={(e) => handleFieldChange('curriculum', e.target.value)}
                  />
                ) : (
                  <Typography>{editableStudent.curriculum || 'Add bathroom...'}</Typography>
                )}
                <Typography variant="h6">Medication: </Typography>
                {isEditing ? (
                  <input
                    type="text"
                    style={{ fontSize: '18px', padding: '5px', width: '100%' }}
                    value={editableStudent.medication || ''}
                    onChange={(e) => handleFieldChange('medication', e.target.value)}
                  />
                ) : (
                  <Typography>{editableStudent.medication || 'Add medication...'}</Typography>
                )}
                <Typography variant="h6">Activities: </Typography>
                {isEditing ? (
                  <input
                    type="text"
                    style={{ fontSize: '18px', padding: '5px', width: '100%' }}
                    value={editableStudent.activities || ''}
                    onChange={(e) => handleFieldChange('activities', e.target.value)}
                  />
                ) : (
                  <Typography>{editableStudent.activities || 'Add activities...'}</Typography>
                )}
              </ReportContent>
            ) : (
              <Typography>Select a student to view their report</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClassroomReport;
